/* Mobile Navigation */

.mobilenav-toggle {
  position: fixed;
  right: 15px;
  top: 15px;
  z-index: 9998;
  border: 0;
  background: none;
  font-size: 24px;
  transition: all 0.4s;
  outline: none !important;
  line-height: 1;
  cursor: pointer;
  text-align: right;
}

.mobilenav-toggle i {
  color: #fff;
}

.exit {
  display: flex;
  justify-content: flex-end;
  width: 300px;
  margin: auto;
}
.exit i {
  margin-top: 3px;
  margin-right: 10px;
  cursor: pointer;
}

.exit i:hover {
  color: #ff8500;
}

.mobilenav {
  top: 55px;
  right: 15px;
  bottom: 15px;
  left: 15px;
  z-index: 9999;
  overflow-y: auto;
  background: #fff;
  transition: ease-in-out 0.2s;
  margin: auto;
  border-radius: 10px;
  padding: 10px 0;
}

.mobilenav * {
  margin: 0;
  padding: 0;
  list-style: none;
}

.mobilenav a {
  display: block;
  position: relative;
  color: #036489;
  padding: 10px 20px;
  font-weight: 500;
  outline: none;
}

.mobilenav a:hover,
.mobilenav .active > a,
.mobilenav li:hover > a {
  color: #ff8500;
  text-decoration: none;
}

.mobilenav .drop-down > a:after {
  content: "\ea99";
  font-family: IcoFont;
  padding-left: 10px;
  position: absolute;
  right: 15px;
}

.mobilenav .active.drop-down > a:after {
  content: "\eaa1";
}

.mobilenav .drop-down > a {
  padding-right: 35px;
}

.mobilenav .drop-down ul {
  display: none;
  overflow: hidden;
}

.mobilenav .drop-down li {
  padding-left: 20px;
}

.mobilenav-overly {
  width: 100%;
  height: 100%;
  z-index: 9997;
  top: 0;
  left: 0;
  position: fixed;
  background: rgba(69, 142, 122, 0.6);
  overflow: hidden;
  display: none;
  transition: ease-in-out 0.2s;
}

.mobilenav-active {
  overflow: hidden;
}

.mobilenav-active .mobile-nav {
  opacity: 1;
  visibility: visible;
}

.mobilenav-active .mobile-nav-toggle i {
  color: #fff;
}
.mobile-nav-toggle i:hover {
  color: #ff8500;
}
