/*--------------------------------------------------------------
# Details
--------------------------------------------------------------*/
.details .container-fluid:nth-child(odd) {
  background-color: rgba(209, 202, 194, 0.1);
}

.details .content h3 {
  font-weight: 600;
  color: #036489;
  margin-bottom: 1rem;
}

.details .content ul {
  list-style: none;
  padding: 0;
}

.details .content ul li {
  padding-bottom: 10px;
}

.details .content ul i {
  font-size: 20px;
  padding-right: 4px;
  color: #ff8500;
}

.details .content p:last-child {
  margin-bottom: 0;
}
