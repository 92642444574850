.CookieConsent {
  background: #448e79 !important;
  z-index: 222222 !important;
}

.CookieConsent a {
  color: #e59d3d;
}

.CookieConsent a:hover {
  color: #ff8500 !important;
}

.CookieConsent button {
  padding: 10px;
  border-radius: 10px !important;
  color: white !important;
  background-color: #e59d3d !important;
}

.CookieConsent button:hover {
  background-color: #ff8500 !important;
}

.CookieConsent span {
  font-size: 15px;
}
