.back-to-top {
  position: fixed;
  display: inline;
  right: 15px;
  bottom: 15px;
  z-index: 99999;
}

.back-to-top i {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  width: 40px;
  height: 40px;
  border-radius: 50px;
  background: #ff8500;
  color: #fff;
  transition: all 0.1s;
}

.back-to-top i:hover {
  background: #2be4a2;
  color: #fff;
}
