/*--------------------------------------------------------------
# Features
--------------------------------------------------------------*/
.features .icon-box {
  display: flex;
  align-items: center;
  padding: 20px;
  background: rgba(209, 202, 194, 0.1);
  transition: ease-in-out 0.3s;
  margin-top: 10px;
}

.features .icon-box i {
  font-size: 32px;
  padding-right: 10px;
  line-height: 1;
}

.features .icon-box h3 {
  font-weight: 700;
  margin: 0;
  padding: 0;
  line-height: 1;
  font-size: 16px;
}

.features .icon-box h3 a {
  color: #036489;
  transition: ease-in-out 0.3s;
}

.features .icon-box h3 a:hover {
  color: #036489;
}

.features .icon-box:hover {
  background: rgba(3, 156, 215, 0.1);
}
